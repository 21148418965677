<template>
  <RouterView />
</template>

<script>
import "./app.css";
import "@/assets/styles/normalize.css";
// import MainComponent from './components/Main/MainComponent.vue'

// import { useMeta } from "vue-meta";

export default {
  name: "App",
  props: ["izd"],
  data() {
    return {
      siteInfo: null,
    };
  },
  // components: {
  //   HeaderComponent,
  //   // MainComponent,
  // },

  // async setup() {
  //   // const isData = getCookie("data");
  //   let title = "Жалюзи и шторы";
  //   // let data = null;

  //   // if (isData) {
  //   //   console.log(1);
  //   //   data = JSON.parse(localStorage.getItem("siteData"));
  //   // } else {

  //   // if (resp.status == "OK") {
  //   //   let d = new Date();
  //   //   d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
  //   //   let expires = "expires=" + d.toUTCString();
  //   //   document.cookie = "data=" + true + ";" + expires + ";path=/";
  //   //   // data = resp.data;
  //   // title = ;
  //   //   console.log(resp.data);
  //   // }
  //   // else {
  //   //   data = "error";
  //   // }
  //   // }

  //   // console.log(data.title);

  //   useMeta({
  //     title: title,
  //     htmlAttrs: { lang: "en", amp: true },
  //   });

  //   // function getCookie(name) {
  //   //   const value = `; ${document.cookie}`;
  //   //   const parts = value.split(`; ${name}=`);
  //   //   if (parts.length === 2) return parts.pop().split(";").shift();
  //   // }
  // },

  // async created() {
  //   const isData = getCookie("data");
  //   // const resp = JSON.parse(localStorage.getItem("siteData"));

  //   // let data = null;
  //   if (isData) {
  //     let resp = JSON.parse(localStorage.getItem("siteData"));
  //     this.siteInfo = resp;
  //   } else {
  //     const resp = await proxyApiSender("landingApi", "getLandingInfo", {});
  //     if (resp.status == "OK") {
  //       let d = new Date();
  //       d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
  //       let expires = "expires=" + d.toUTCString();
  //       document.cookie = "data=" + true + ";" + expires + ";path=/";
  //       localStorage.setItem("siteData", JSON.stringify(resp.data));
  //       this.siteInfo = resp.data;
  //     } else {
  //       localStorage.setItem("siteData", "error");
  //     }
  //   }

  //   //   data = JSON.parse(localStorage.getItem("siteData"));
  //   // } else {

  //   // if (resp.status == "OK") {
  //   //   let d = new Date();
  //   //   d.setTime(d.getTime() + 1 * 60 * 60 * 1000);
  //   //   let expires = "expires=" + d.toUTCString();
  //   //   document.cookie = "data=" + true + ";" + expires + ";path=/";
  //   //   // data = resp.data;
  //   // title = ;
  //   //   console.log(resp.data);
  //   // }
  //   // else {
  //   //   data = "error";
  //   // }
  //   // }

  //   // console.log(data.title);

  //   function getCookie(name) {
  //     const value = `; ${document.cookie}`;
  //     const parts = value.split(`; ${name}=`);
  //     if (parts.length === 2) return parts.pop().split(";").shift();
  //   }
  // },

  async beforeMount() {
    // const resp = JSON.parse(localStorage.getItem("siteData"));

    this.$store.dispatch("startListener");
    // this.$store.commit("setHeaderInfo", resp.headerInfo);
    // this.$store.commit("setSliderInfo", resp.sliderInfo);
    // this.$store.commit("setAboutUsInfo", resp.aboutUsInfo);
    // this.$store.commit("setAdvantagesInfo", resp.advantagesInfo);
    // this.$store.commit("setFaqInfo", resp.faqInfo);
    // this.$store.commit("setReviewsInfo", resp.reviewsInfo);
    // this.$store.commit("setHowWeWorkInfo", resp.howWeWorkInfo);
    // this.$store.commit("setFormsInfo", resp.formsInfo);
    // this.$store.commit("setFooterInfo", resp.footerInfo);

    // console.log(resp.data);

    // localStorage.setItem("siteData", JSON.stringify(resp.data));
  },

  methods: {
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return JSON.parse(parts.pop().split(";").shift());
    },
  },
};
</script>

<style>
html {
  font-family: "Circe";
}

body {
  background-color: #f5f5f5;
}
</style>
