<template>
  <section v-if="reviewsInfo" class="reviews">
    <wrapper-component :id="'reviews'">
      <TitleComponent :title="reviewsInfo.title" :customClass="'reviews__title'" />
      <iframe
        :src="
          'https://yandex.ru/maps-reviews-widget/' +
          reviewsInfo.yandexReviewId +
          '?comments'
        "
      ></iframe>
    </wrapper-component>
  </section>
</template>
<script>
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import "./reviews.css";
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";

export default {
  name: "HeroSliderComponent",
  props: ["reviewsInfo"],
  components: {
    WrapperComponent,
    TitleComponent,
  },
  computed: {
    fullTablet() {
      return this.$store.getters.fullTablet;
    },
    fullDesktop() {
      return this.$store.getters.fullDesktop;
    },

    // reviewsInfo() {
    //   return this.$store.getters.reviewsInfo;
    // },
  },
};
</script>
