<template>
  <div v-if="headerInfo">
    <header class="header">
      <wrapper-component :classes="''">
        <div style="width: 100%" v-if="!fullDesktop">
          <burger-component :list="headerInfo.dropdownList" />
        </div>
        <div class="header__container">
          <a class="header__logo-link" href="#">
            <img
              class="header__logo header__logo_foroom"
              :width="fullDesktop ? 190 : 215"
              src="@/assets/images/foroom_logo.png"
              alt=""
            />
          </a>
          <span class="header__mark">{{ headerInfo.oficial_dealer_text }}</span>
          <div class="user-logo">
            <img
              class="header__logo"
              :width="
                fullDesktop ? headerInfo.logoSizeDesktop : headerInfo.logoSizeMobile
              "
              :src="
                'https://media.foroom.ru/images/landing-content/' +
                subdomain +
                '/' +
                headerInfo.logo
              "
              alt=""
            />
          </div>

          <div class="header__foroom-block">
            <span>{{ headerInfo.slogan }}</span>
          </div>
          <div class="header__contacts">
            <div class="header__numbers">
              <a
                v-for="item of headerInfo.phoneNumbers"
                :key="item"
                class="header__number"
                :href="'tel:' + item"
                @click="sendMetrika"
                >{{ item }}
                <span
                  v-if="
                    headerInfo.phoneNumbers.indexOf(item) <
                    headerInfo.phoneNumbers.length - 1
                  "
                  >,</span
                ></a
              >
            </div>
            <a v-if="fullDesktop" class="header__number" href="#">{{
              headerInfo.dealer_address
            }}</a>
          </div>
        </div>
        <div v-if="fullDesktop">
          <navigation-component
            :list="headerInfo.navList"
            :dropdownList="headerInfo.dropdownList"
          />
        </div>
      </wrapper-component>
    </header>
    <section class="underheader">
      <wrapper-component :classes="'underheader__container'">
        <span class="underheader__mark">
          {{ headerInfo.underheader1 }}
        </span>

        <span class="underheader__mark"
          ><span class="underheader__mark underheader__mark_red"
            >{{ headerInfo.underheader2 }}
          </span></span
        >
      </wrapper-component>
    </section>
  </div>
</template>

<script>
import "./header.css";
import WrapperComponent from "../Wrappers/WrapperComponent.vue";
import BurgerComponent from "./Burger/BurgerComponent.vue";
import NavigationComponent from "./Navigation/NavigationComponent.vue";

export default {
  components: { WrapperComponent, BurgerComponent, NavigationComponent },
  name: "HeaderComponent",
  props: ["headerInfo"],
  methods: {
    sendMetrika() {
      window.ym(process.env.VUE_APP_METRIKA_ID, "reachGoal", "clickOnPhone");
    },
  },
  computed: {
    fullDesktop() {
      return this.$store.getters.fullDesktop;
    },
    subdomain() {
      return this.$store.getters.subdomain;
    },
  },
};
</script>
