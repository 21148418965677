import { createStore } from 'vuex'
import { commonModule } from './modules/common'
// import { headerInfoModule } from './modules/Header/headerInfo'
// import { aboutUsInfoModule } from './modules/AboutUs/aboutUsInfo'
// import { howWeWorkInfoModule } from './modules/HowWeWork/howWeWorkInfo'
// import { advantagesInfoModule } from './modules/Advantages/advantagesInfo'
// import { formsInfoModule } from './modules/Forms/forms'
// import { reviewsInfoModule } from './modules/Reviews/reviewsInfo'
// import { faqInfoModule } from './modules/FAQ/faqInfo'
// import { footerInfo } from './modules/Footer/footerInfo'
// import { sliderInfoModule } from './modules/Slider/slider'
export default createStore({
    modules: {
        commonModule,
        // headerInfoModule,
        // aboutUsInfoModule,
        // howWeWorkInfoModule,
        // advantagesInfoModule,
        // formsInfoModule,
        // reviewsInfoModule,
        // faqInfoModule,
        // footerInfo,
        // sliderInfoModule
    }

})