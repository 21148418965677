<template>
  <wrapper-component :id="'aboutus'" v-if="aboutUsInfo">
    <TitleComponent :title="aboutUsInfo.title" />
    <p
      class="standart-text"
      v-for="(item, index) of aboutUsInfo.textList"
      :key="index"
      v-html="item"
    ></p>
    <div class="our-command">
      <h2 class="our-command__title">Наша команда</h2>
      <ul class="our-command__list">
        <li
          class="our-command__item"
          v-for="(item, index) of aboutUsInfo.commandList"
          :key="index"
        >
          <div class="our-command__img-container">
            <img
              :src="
                'https://media.foroom.ru/images/landing-content/' +
                subdomain +
                `/command/${item.img}`
              "
              class="our-command__img"
              :alt="'command_' + (index + 1)"
            />
          </div>

          <span class="our-command__name">{{ item.name }}</span>
          <span class="our-command__subtitle bold standart-text">{{
            item.subtitle
          }}</span>
          <span class="our-command__description standart-text">{{
            item.description
          }}</span>
        </li>
      </ul>
    </div>
  </wrapper-component>
</template>

<script>
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
import "./about-us.css";
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
export default {
  name: "AboutUsComponent",
  components: { WrapperComponent, TitleComponent },
  props: ["aboutUsInfo"],

  computed: {
    subdomain() {
      return this.$store.getters.subdomain;
    },
  },
};
</script>
