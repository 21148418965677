<template>
  <section>
    <wrapper-component v-if="advantagesInfo" :id="'advantages'">
      <TitleComponent :title="advantagesInfo.title" :customClass="'advantages__title'" />
      <div class="advantages__content">
        <AdvantageItem
          v-for="(item, index) in advantagesInfo.list"
          :key="index"
          :img="item.img"
          :text="item.text"
        />
      </div>
    </wrapper-component>
  </section>
</template>

<script>
import "./advantages.css";
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import AdvantageItem from "./AdvantageItem/AdvantageItem.vue";
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
export default {
  name: "AdvantagesComponent",
  props: ["sectionName", "title", "advantagesInfo"],
  components: { WrapperComponent, AdvantageItem, TitleComponent },
  // computed: {
  //   advantagesInfo() {
  //     return this.$store.getters.advantagesInfo;
  //   },
  // },
};
</script>

<style></style>
