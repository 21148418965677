<template>
  <section v-if="howWeWorkInfo">
    <wrapper-component :id="'howwework'">
      <TitleComponent :title="howWeWorkInfo.title" :customClass="'working__title'" />
      <ListItem
        v-for="(item, index) in howWeWorkInfo.list"
        :key="index"
        :color="item.color"
        :listNumber="index + 1"
        :text="item.text"
      />
    </wrapper-component>
  </section>
</template>

<script>
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import ListItem from "./ListItem/ListItem";
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
export default {
  name: "HowWeWork",
  components: { WrapperComponent, ListItem, TitleComponent },
  props: ["howWeWorkInfo"],
  // computed: {
  //   howWeWorkInfo() {
  //     return this.$store.getters.howWeWorkInfo;
  //   },
  // },
};
</script>

<style></style>
