import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { createYmaps } from 'vue-yandex-maps';
import { createMetaManager } from 'vue-meta'
  



createApp(App).use(router).use(createMetaManager()).use(createYmaps({
    apikey: 'fd261ce4-b879-4d4a-a897-bb15cf1371e0',
  })).use(store).mount('#app')
