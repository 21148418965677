<template>
  <wrapper-component v-if="faqInfo" :id="'faq'">
    <TitleComponent :title="faqInfo.title" />
    <div v-for="(item, index) of faqInfo.accordeonList" :key="index">
      <button
        @click="toggleAccordeon(index + 1)"
        :class="'accordion ' + (accordeon === index + 1 ? 'active' : '')"
      >
        {{ item.title }}
      </button>
      <div v-if="accordeon === index + 1" class="panel">
        <p v-if="item.text" class="standart-text" v-html="item.text"></p>
        <div v-else>
          <PhotoModal />
        </div>
      </div>
    </div>
  </wrapper-component>
</template>

<script>
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
import "./accordeon.css";
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import PhotoModal from "./PhotoModal/PhotoModal.vue";

export default {
  name: "AccordeonComponent",
  components: { WrapperComponent, TitleComponent, PhotoModal },
  props: ["faqInfo"],
  data() {
    return {
      accordeon: 0,
      modal: false,
    };
  },
  methods: {
    toggleAccordeon(acc) {
      if (this.accordeon === acc) {
        this.accordeon = 0;
      } else {
        this.accordeon = acc;
      }
    },
    // openModal(e){
    //   console.log(e.target);

    // },
  },
  // computed: {
  //   faqInfo() {
  //     return this.$store.getters.faqInfo;
  //   },
  // },
};
</script>
