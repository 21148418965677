

export default function proxyApiSender (module, action, methodData) {
    return new Promise(resolve => {
        makePost (module, action, methodData, resolve) 
    });
}

function makePost (module, action, methodData, cb) {
    // this.url = 'https://api.foroom.ru/';

    const url = 'https://sale.foroom.ru/proxy/proxy.php';



    const key = process.env.VUE_APP_API_KEY;
    const userdata = {
        auth: { session: process.env.VUE_APP_API_SESSION },
        data: methodData,
        module: module,
        key: key,
        cmd: action
    }

        var data = new FormData();
        data.append('json', JSON.stringify(userdata));
        if (!navigator.onLine) {
            if (typeof cb !== "undefined" && cb != null) cb({ status: 'OFFLINE', descr: '' });
        } else {
            var xmlhttp = new XMLHttpRequest();

            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState === XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                    if (xmlhttp.status === 200) {
                        var headers = xmlhttp
                            .getAllResponseHeaders()
                            .split('\r\n')
                            .reduce((result, current) => {
                                var [name, value] = current.split(': ');
                                result[name] = value;
                                return result;
                            }, {});
                        var contentType = 'application/json';
                        if (headers["content-type"]) contentType = headers["content-type"];

                        var respond = { status: 'OK' };
                        if (contentType === 'application/json') {
                            respond = JSON.parse(xmlhttp.responseText);
                        } else {
                            // var filename = 'file.txt';
                            console.log(xmlhttp.responseType);

                            // if (headers["filename"]) filename = headers["filename"];
                            // var blob = new Blob([xmlhttp.response], { type: contentType });
                            // //var file = new File([blob],filename,{type:contentType});
                            // self.downloadAsFile(blob, filename);
                        }
                     


                        if (typeof cb !== "undefined" && cb != null) cb(respond);
                    } else {
                        if (cb) cb({ status: 'ERROR' });
                        console.log('РћРЁРР‘РљР AJAX Р·Р°РїСЂРѕСЃР°:');
                    }
                }
            };

            xmlhttp.open("POST", url, true);
            xmlhttp.send(data);
        }
}

